import { Typography } from "@material-ui/core";

export const ComingSoon = () => {
  return (
    <Typography
      style={{
        fontFamily: "'Avenir','Inter'",
        fontWeight: 400,
        fontSize: "9px",
        border: "0.5px solid red",
        borderRadius: "4px",
        padding: "0px 8px",
        marginLeft: "10px",
        color: "red",
      }}
    >
      coming soon
    </Typography>
  );
};
