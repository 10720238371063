import React from "react";
import { KeyboardArrowRight } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";

import { Skeleton } from "@mui/material";
import ComponentCard from "./ComponentCard";
import { returnLinkUrl } from "../../../common/helpers/helperFunctions";
import { getUserIdFieldBasedOnUserDetail } from "../../../SettingsLayout/Pages/Customizations/utils/customizationutils";

const Container = ({
  accountInfo,
  taskData,
  isTaskLoading,
  isAccountLoading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <UserDetails
        classes={classes}
        accountInfo={accountInfo}
        isAccountLoading={isAccountLoading}
      />
      <OrgDetails
        classes={classes}
        accountInfo={accountInfo}
        isAccountLoading={isAccountLoading}
      />
      <PendingTasks
        taskData={taskData?.data}
        isTaskLoading={isTaskLoading}
        classes={classes}
      />
    </div>
  );
};
export default Container;

const conditions = (details, classes) => {
  if (details?.contentType === "Apps") {
    return (
      <Tooltip title={details?.content?.name}>
        <Typography className={`${classes.bottomUsrTxt} ${classes.truncated}`}>
          {details?.content?.name || "--"}
        </Typography>
      </Tooltip>
    );
  } else if (details?.contentType === "External link") {
    return details?.contentType === "External link" ? (
      <a
        href={returnLinkUrl(`${details.linkUrl}`)}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <Typography className={`${classes.linkTxt} ${classes.truncated}`}>
          {details?.linkText}
        </Typography>
      </a>
    ) : (
      <Typography variant="h6" className={classes.bottomUsrTxt}>
        {"--"}
      </Typography>
    );
  } else if (details?.contentType === "Documents") {
    return (
      <a
        href={returnLinkUrl(`${details.file}`)}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <div className={classes.policyCover}>
          <Tooltip title={details?.documentType}>
            <Typography
              variant="h6"
              className={`${classes.bottomUsrTxt} ${classes.truncated}`}
            >
              {details?.documentType || "--"}
            </Typography>
          </Tooltip>
          <Typography variant="body2" noWrap className={classes.eightItems}>
            {" "}
            1 item
          </Typography>

          <KeyboardArrowRight
            style={{
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
        </div>
      </a>
    );
  } else if (details?.contentType === "User details") {
    return (
      <Tooltip
        title={getUserIdFieldBasedOnUserDetail(details?.informationType)}
      >
        <Typography
          variant="h6"
          className={`${classes.bottomUsrTxt} ${classes.truncated}`}
        >
          {getUserIdFieldBasedOnUserDetail(details?.informationType) || "--"}
        </Typography>
      </Tooltip>
    );
  } else if (details?.contentType === "None") {
    return (
      <Tooltip title={details?.title}>
        <Typography
          variant="h6"
          className={`${classes.bottomUsrTxt} ${classes.truncated}`}
        >
          {details?.title || "--"}
        </Typography>
      </Tooltip>
    );
  } else {
    return "--";
  }
};
const UserDetails = ({ classes, accountInfo, isAccountLoading }) => {
  const Body = () => (
    <div
      className={classes.fleexCol}
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "0.7rem",
        justifyContent: "space-between",
        fontFamily: "Avenir-Medium,Inter",
      }}
    >
      {accountInfo?.personalDetails?.map((details, index) => (
        <div style={{ width: "48%" }} key={index}>
          <Typography className={classes.bottomSpacer}>
            {details?.title}
          </Typography>
          {conditions(details, classes)}
        </div>
      ))}
      {!accountInfo?.personalDetails?.length && (
        <div style={{ width: "100%", marginTop: "2rem" }}>
          <h4
            style={{
              minWidth: "100%",
              marginLeft: "50%",
              fontStyle: "italic",
            }}
          >
            No data found
          </h4>
        </div>
      )}
    </div>
  );

  return (
    <ComponentCard
      isLoading={isAccountLoading}
      classes={classes}
      Body={Body}
      laptopWidthMD={"32%"}
      laptopWidthSM={"48%"}
      title={"Personal Details"}
    />
  );
};

const OrgDetails = ({ classes, accountInfo, isAccountLoading }) => {
  const Body = () => {
    return (
      <div
        className={classes.fleexCol}
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "0.7rem",
          justifyContent: "space-between",
        }}
      >
        {accountInfo?.organizationDetails?.map((details) => (
          <div style={{ width: "48%" }}>
            <Typography className={classes.bottomSpacer}>
              {details?.title}
            </Typography>
            {conditions(details, classes)}
          </div>
        ))}

        {!accountInfo?.personalDetails?.length && (
          <div style={{ width: "100%", marginTop: "2rem" }}>
            <h4
              style={{
                minWidth: "100%",
                marginLeft: "50%",
                fontStyle: "italic",
              }}
            >
              No data found
            </h4>
          </div>
        )}
      </div>
    );
  };

  return (
    <ComponentCard
      isLoading={isAccountLoading}
      laptopWidthMD={"35%"}
      laptopWidthSM={"48%"}
      classes={classes}
      Body={Body}
      title={"Organization Details"}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "18px",
    width: "100%",
    maxHeight: "208px",
    minHeight: "200px",
    "@media (max-width: 1170px)": {
      flexWrap: "wrap",
      maxHeight: "208px",
    },
    "@media (max-width: 800px)": {
      flexDirection: "column",
      flexWrap: "nowrap",
      maxHeight: "fit-content",
      width: "98%",
    },
    "@media (max-width: 500px)": {
      width: "50%",
    },
  },
  truncated: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    minWidth: "50px",
  },
  userHeadingOne: {
    width: "100%",
    fontWeight: "500",
    fontSize: "22px",
    overflow: "unset",
  },
  deptDivOne: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  bottomUsrTxt: {
    margin: "4px 0px 8px",
    fontSize: "16px",
    fontWeight: 500,
    color: "#535353",
  },
  bottomSpace2: {
    marginTop: "4px",
    fontSize: "1rem",
  },
  bottomSpacer: {
    color: "#ABABAB",
    fontWeight: "400",
    //marginBottom: "10px",
    fontSize: "14px",
  },
  orgHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    gap: "3px",
    //width: "35%",
    //width: "375px",
    borderRadius: "10px",
    background: "#FFFFFF",
    minHeight: "100%",
    overflowY: "auto",
    "@media (max-width: 1170px)": {
      width: "46%",
      minHeight: "15rem",
    },
    "@media (max-width: 800px)": {
      width: "98%",
    },
  },
  linkTxt: {
    margin: "4px  0 11px",
    fontSize: "16px",
    fontWeight: "500",
    textDecoration: "underline",
    color: "#535353",
  },
  orgDetails: {
    width: "100%",
    margin: "10px 0 15px",
    fontWeight: "500",
    fontSize: "22px",
    overflow: "unset",
  },
  docWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  policyCover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2px",
  },
  textSize: {
    margin: "0px",
    fontSize: "1rem",
  },
  eightItems: {
    padding: "2px 8px",
    lineHeight: "16px",
    display: "inline-block",
    margin: "0px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    backgroundColor: "#DE543933",
    color: "#DE5439",
    minWidth: "4rem",
    fontSize: "11px",
  },
  pendingTop: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Avenir-Medium,Inter",
  },
  nameTitle: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  approvalText: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#535353",
    margin: "0px",
  },
  userName: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#535353",
    padding: "2px 12px",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
  },
  userDays: {
    padding: "2px 6px",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "25px",
    fontWeight: "500",
    backgroundColor: "#DE543933",
  },
}));

const PendingTasks = ({ classes, taskData, isTaskLoading }) => {
  const textColor = ["#52DE3929", "#398EDE29", "#DEB23929"];

  const returnLink = (data) => {
    if (data?.task?.name?.toLowerCase() === "approval") {
      return `/approval/${data?.app?.account?.slug}/${
        data?.app?.slug
      }?workflowInstanceId=${data?.taskStatus?.workflowInstance}&taskId=${
        data?.task?.id || data?.taskStatus?.task
      }&appId=${data?.app?.id}&isApproval=true`;
    } else {
      return `/run/${data?.app?.account?.slug}/${data?.app?.slug}/${
        data?.screensInfo?.slug
      }?workflowInstanceId=${data?.taskStatus?.workflowInstance}&taskId=${
        data?.task?.id || data?.taskStatus?.task
      }&appId=${data?.app?.id}`;
    }
  };

  const Body = () => {
    return (
      <>
        {taskData?.map((taskDataObj, index) => (
          <div key={index} className={classes.nameTitle}>
            <Tooltip title={taskDataObj?.app?.name}>
              <Typography
                className={`${classes.approvalText} ${classes.truncated}`}
                variant="h6"
              >
                {taskDataObj?.app?.name}
              </Typography>
            </Tooltip>
            <Tooltip
              title={`${taskDataObj?.user?.firstName} ${taskDataObj?.user?.lastName}`}
            >
              <Typography
                className={`${classes.userName} ${classes.truncated}`}
                style={{
                  backgroundColor: textColor[index % textColor.length],
                }}
                noWrap
                variant="h6"
              >
                {`${taskDataObj?.user?.firstName} ${taskDataObj?.user?.lastName}`}
              </Typography>
            </Tooltip>
            {/*THIS IS A NEEDED CODE BUT FOR THE FUTURE*/}
            {/* <h4 className={classes.userDays}> {taskDataObj.days}</h4> */}
            {
              <Link
                to={returnLink(taskDataObj)}
                style={{ textDecoration: "none" }}
              >
                <KeyboardArrowRight
                  style={{
                    fontSize: "18px",
                    cursor: "pointer",
                    marginTop: "4px",
                  }}
                />
              </Link>
            }
          </div>
        ))}
        {!taskData?.length && (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Typography variant="body3" noWrap>
              No pending task data found
            </Typography>
          </div>
        )}
      </>
    );
  };

  const SideElement = () => {
    return (
      <Link style={{ color: "black" }} to="/">
        <Typography variant="body3" noWrap>
          See all{" "}
        </Typography>
      </Link>
    );
  };

  return (
    <ComponentCard
      isLoading={isTaskLoading}
      classes={classes}
      Body={Body}
      laptopWidthMD={"33%"}
      laptopWidthSM={"48%"}
      title={"Pending Tasks"}
      SideElement={SideElement}
    />
  );
};
