import { Collapse, IconButton, Typography } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { MdAnalytics } from "react-icons/md";
//import MenuActions from "./MenuActions";
import { Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  editorNavigationUrls,
  otherProtectedUrls,
  unprotectedUrls,
} from "../../../common/utils/lists";
import { handleRoleActionAccess } from "../../../common/utils/userRoleEvaluation";
import { APPS_CONTROL_MODES } from "../../../EditorLayout/Pages/Workflow/components/utils/constants";

import MoreVert from "@material-ui/icons/MoreVert";
import { Skeleton } from "@mui/material";

const appsControlMode = APPS_CONTROL_MODES.APP;

const AppsByCategory = (props) => {
  const { classes, filteredAppsData, colors, isAppsLoading } = props;
  const [categoryObj, setCategoryObj] = useState({});
  const [categoryApps, setCategoryApps] = useState({});
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [appData, setAppData] = useState({});

  useEffect(() => {
    if (filteredAppsData?.length) {
      let activeCategories = {};
      const categoryList = filteredAppsData?.map((app) => {
        if (!activeCategories?.[`${app?.category?.name}`]) {
          activeCategories = {
            ...categoryObj,
            ...activeCategories,
            [app?.category?.name]: false,
          };
        }
        return app?.category?.name;
      });
      setCategories(Object.keys(activeCategories));
      setCategoryObj(activeCategories);
    }
  }, [filteredAppsData]);

  // useEffect(() => {
  //   console.log(appData);
  // }, [appData]);

  useEffect(() => {
    const itemByCategory = filteredAppsData?.reduce((acc, item) => {
      const categoryName = item?.category?.name;

      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }

      acc[categoryName]?.push(item);

      return acc;
    }, {});

    setCategoryApps(itemByCategory);
  }, [filteredAppsData]);

  const handleMoreOptionClick = (e, app) => {
    setAnchorEl(e.currentTarget);
    setAppData(app);
  };

  return (
    <div className={classes.rightSect}>
      <Typography
        style={{
          margin: "24px 24px 26px",
          fontSize: "16px",
          fontWeight: "800",
          color: "#292929",
        }}
        noWrap
      >
        All Categories
      </Typography>
      {isAppsLoading ? (
        <div
          style={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Skeleton width="90%" height="40px" />
          <Skeleton width="90%" height="40px" />
          <Skeleton width="90%" height="40px" />
          <Skeleton width="90%" height="40px" />
        </div>
      ) : (
        <div>
          {filteredAppsData?.length ? (
            <div>
              {categories?.map((category, index) => (
                <div key={index}>
                  <div
                    style={{
                      width: "100%",
                      background: "#DE54390D",
                      cursor: "pointer",
                      padding: "1px 6px 0px 27px",
                      height: "40px",
                      marginBottom: categoryObj?.[category] ? 0 : 24,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "8px",
                    }}
                    onClick={() =>
                      setCategoryObj(() => ({
                        ...categoryObj,
                        [category]: !categoryObj[category],
                      }))
                    }
                  >
                    <div
                      style={{
                        background: colors[index % colors.length].pri,
                      }}
                      className={classes.appsIcon}
                    >
                      <MdAnalytics
                        color={colors[index % colors.length].sec}
                        style={{ fontSize: "14px" }}
                      />
                    </div>
                    <Typography
                      style={{ fontWeight: "500", fontSize: "16px" }}
                      noWrap
                    >
                      {category}
                    </Typography>
                    {!categoryObj?.[category] ? (
                      <KeyboardArrowRight
                        style={{ marginLeft: "auto", color: "#292929" }}
                      />
                    ) : (
                      <KeyboardArrowDown
                        style={{ marginLeft: "auto", color: "#292929" }}
                      />
                    )}
                  </div>
                  <Collapse
                    in={categoryObj?.[category]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div
                      style={{
                        padding: "12px 24px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {categoryApps?.[category]?.map((app, index) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "3rem",
                          }}
                          key={index}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`${unprotectedUrls.RUN}/${app?.account?.slug}/${app?.slug}`}
                            target="_blank"
                          >
                            <Typography
                              style={{
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                              noWrap
                              className={classes.onHover}
                            >
                              {app?.name}
                            </Typography>
                          </Link>
                          {/*THIS IS NEEDED FOR FUTURE CASES*/}
                          {/* <IconButton
                          title="menuIcon"
                          style={{ padding: 7 }}
                          onClick={(e) => handleMoreOptionClick(e, app)}
                        >
                          <MoreVert style={{ fontSize: 18 }} />
                        </IconButton>{" "} */}
                        </div>
                      ))}
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <h4
                style={{
                  width: "90%",
                  marginLeft: "5rem",
                  marginTop: "15%",
                  fontStyle: "italic",
                }}
              >
                No data found
              </h4>
            </div>
          )}
        </div>
      )}
      <MenuActions
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        appData={appData}
      />
    </div>
  );
};

export default AppsByCategory;

const MenuActions = ({
  setAnchorEl,
  anchorEl,
  active,
  sendMenuAction,
  appData,
}) => {
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={"screens-menu"}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {[{ name: "Edit App" }]?.map(({ name }, idx) =>
          idx !== 0 ? (
            <MenuItem
              button
              key={idx}
              onClick={(e) => {
                sendMenuAction && sendMenuAction(name);
                handleMenuClose();
              }}
              style={{ fontWeight: 300, lineHeight: "19px" }}
            >
              {name}
            </MenuItem>
          ) : (
            handleRoleActionAccess(
              { pageTitle: APPS_CONTROL_MODES.APP },
              "UPDATE",
              appData?.ownerGroup
            ) && (
              <MenuItem
                component={Link}
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "gray",
                }}
                to={`${otherProtectedUrls.EDITOR}/${
                  appData?.app || appData?.id
                }${editorNavigationUrls.UI_EDITOR}`}
              >
                {name}
              </MenuItem>
            )
          )
        )}
      </Menu>
    </div>
  );
};
