import React from "react";

const SwitchModeBrush = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8101 3.94009C20.5001 7.21009 17.5101 11.4801 14.6601 14.2701C14.2501 11.6901 12.1901 9.67009 9.59009 9.31009C12.3901 6.45009 16.6901 3.42009 19.9701 2.10009C20.5501 1.88009 21.1301 2.05009 21.4901 2.41009C21.8701 2.79009 22.0501 3.36009 21.8101 3.94009Z"
        fill="#292929"
      />
      <path
        d="M13.7801 15.09C13.5801 15.26 13.3801 15.43 13.1801 15.59L11.3901 17.02C11.3901 16.99 11.3801 16.95 11.3801 16.91C11.2401 15.84 10.7401 14.85 9.93012 14.04C9.11012 13.22 8.09012 12.72 6.97012 12.58C6.94012 12.58 6.90012 12.57 6.87012 12.57L8.32012 10.74C8.46012 10.56 8.61012 10.39 8.77012 10.21L9.45012 10.3C11.6001 10.6 13.3301 12.29 13.6701 14.43L13.7801 15.09Z"
        fill="#292929"
      />
      <path
        d="M10.43 17.6198C10.43 18.7198 10.01 19.7698 9.21001 20.5598C8.60001 21.1798 7.78001 21.5998 6.78001 21.7198L4.33001 21.9898C2.99001 22.1398 1.84001 20.9898 1.99001 19.6398L2.26001 17.1798C2.50001 14.9898 4.33001 13.5898 6.27001 13.5498C6.46001 13.5398 6.67001 13.5498 6.87001 13.5698C7.72001 13.6798 8.54001 14.0698 9.23001 14.7498C9.90001 15.4198 10.28 16.2098 10.39 17.0398C10.41 17.2398 10.43 17.4298 10.43 17.6198Z"
        fill="#292929"
      />
    </svg>
  );
};

export default SwitchModeBrush;
