import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "./appsStyle";
import Typography from "@material-ui/core/Typography";
import PortalLayout from "../PortalAppLayout/PortalLayout"; // Import the missing component

import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { MdKeyboardArrowDown } from "react-icons/md";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import useCustomQuery from "../../common/utils/CustomQuery";

import { getCategories } from "../../common/components/Query/AppsQuery/queryApp";
import { RecordsTable } from "./components/RecordsTable";
import MainPortalLayout from "../MainPortalLayout";

const ViewRecords = () => {
  const classes = useStyles(makeStyles);
  const [categories, setCategories] = useState([]);

  const [categoryVal, setCategoryVal] = useState("All");
  const [sortLevel, setSortLevel] = useState({});
  const [statusVal, setStatusVal] = useState("");

  useEffect(() => {
    document.title = "Records";
  }, []);

  const handleFilterItemClick = (filterObj) => {
    if (filterObj.filterType === "category") {
      setCategoryVal(filterObj?.id);
    } else {
      setStatusVal(filterObj.status);
    }
  };

  const handleSortItemClick = (index) => {
    switch (index) {
      case 0:
        setSortLevel(null);
        break;
      case 1:
        setSortLevel({ name: "app", type: "asc" });
        break;
      case 2:
        setSortLevel({ name: "app", type: "desc" });
        break;
      default:
        break;
    }
  };

  const handleCategoriesListSuccess = ({ data }) => {
    const categoriesData = data?.data?.data?.map((category) => {
      return { id: category?.id, name: category?.name };
    });
    return setCategories(categoriesData);
  };

  useCustomQuery({
    apiFunc: getCategories,
    queryKey: ["listCategories"],
    onSuccess: handleCategoriesListSuccess,
  });

  return (
    <div>
      <PortalLayout>
        <MainPortalLayout
          sortItems={["Undo sort", "Date (Oldest)", "Date (Newest)"]}
          sortFunc={handleSortItemClick}
          filterFunc={handleFilterItemClick}
          filterItems={categories}
          pageTitle={"Records"}
          pageSubtitle={
            "Track all initiations performed by you and from your department"
          }
        >
          <div style={{ marginTop: "2rem" }}>
            <FullWidthTabs
              setSortLevel={setSortLevel}
              categoryVal={categoryVal}
              statusVal={statusVal}
              sortLevel={sortLevel}
            />
          </div>
        </MainPortalLayout>
      </PortalLayout>
    </div>
  );
};

const options = ["Members ", "Executive "];

function SplitButton() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const btnStyles = makeStyles((theme) => ({
    customButton: {
      backgroundColor: "#F8F8F8",
      color: "black",
      "&:hover": {
        backgroundColor: "lightgray",
      },
    },
  }));
  const classes = btnStyles();

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <ButtonGroup
        variant="contained"
        //color="primary"
        ref={anchorRef}
        aria-label="split button"
        style={{ height: "2.5rem" }}
      >
        <Button
          title="menu_btn"
          color="primary"
          style={{ textTransform: "none" }}
          onClick={handleClick}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-haspopup="menu"
          onClick={handleToggle}
          title="arrow_down"
          className={classes.customButton}
        >
          <MdKeyboardArrowDown size={26} />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ width: "100%", zIndex: "50" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ width: "100%" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options?.map((option, index) => (
                    <MenuItem
                      title={"menu_item"}
                      key={option}
                      //disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function FullWidthTabs({ categoryVal, statusVal, sortLevel, setSortLevel }) {
  const [value, setValue] = React.useState("all");

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  const tabStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "none",
      width: "100%",
      "& .MuiTabs-indicator": {
        display: "none !important",
      },
    },
    appBar: {
      width: "fit-content",
      marginRight: "auto",
      backgroundColor: "#F8F8F8",
      boxShadow: "none",
      borderRadius: "8px",
      border: "none",
      padding: " 10px 0px",
      "& .MuiTabs-root": {
        minHeight: "10px",
      },
    },
    tab: {
      textTransform: "none",
      color: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0px 20px ",
      margin: "0px",
      minHeight: "10px",
      minWidth: "10px",
      "& > :first-child": {
        fontSize: "1rem",
        padding: "1px 20px",
      },
    },
    activeTab: {
      "& > :first-child": {
        backgroundColor: "midnightblue",
        borderRadius: "5px",
        color: "#fff",
        "&:hover": {
          backgroundColor: "darkblue",
        },
      },
    },
    tabPanel: {
      backgroundColor: theme?.palette?.background?.paper,
      "& > :first-child": {
        padding: "0px",
      },
    },
  }));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = tabStyles();
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          //variant="fullWidth"
        >
          <Tab
            className={`${classes.tab} ${
              value === "all" ? classes.activeTab : ""
            }`}
            value="all"
            label="All"
            {...a11yProps("all")}
            style={{ borderRight: "2px solid #858585" }}
          />
          <Tab
            className={`${classes.tab} ${
              value === "department" ? classes.activeTab : ""
            }`}
            value="department"
            label="Departmental"
            {...a11yProps("department")}
            style={{ borderRight: "2px solid #858585" }}
          />
          <Tab
            className={`${classes.tab} ${
              value === "directreport" ? classes.activeTab : ""
            }`}
            value="directreport"
            label="Assigned"
            {...a11yProps("directreport")}
            style={{ borderRight: "2px solid #858585" }}
          />
          <Tab
            className={`${classes.tab} ${
              value === "own" ? classes.activeTab : ""
            }`}
            value="own"
            label="Personal"
            {...a11yProps("own")}
          />
        </Tabs>
      </AppBar>

      <TabPanel className={classes.tabPanel} value={value} index={"all"}>
        <RecordsTable
          category={categoryVal}
          filterLevel={value === "all" ? "" : value}
          sortLevel={sortLevel}
          statusVal={statusVal}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={"department"}>
        <RecordsTable
          sortLevel={sortLevel}
          statusVal={statusVal}
          filterLevel={value}
          category={categoryVal}
        />
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        value={value}
        index={"directreport"}
      >
        <RecordsTable
          sortLevel={sortLevel}
          filterLevel={value}
          category={categoryVal}
          statusVal={statusVal}
        />
      </TabPanel>
      <TabPanel className={classes.tabPanel} value={value} index={"own"}>
        <RecordsTable
          sortLevel={sortLevel}
          filterLevel={value}
          category={categoryVal}
          statusVal={statusVal}
        />
      </TabPanel>
    </div>
  );
}

export default ViewRecords;
