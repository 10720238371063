const ReturnToPortalIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1299 19.0601H7.12988C6.71988 19.0601 6.37988 18.7201 6.37988 18.3101C6.37988 17.9001 6.71988 17.5601 7.12988 17.5601H15.1299C17.4699 17.5601 19.3799 15.6501 19.3799 13.3101C19.3799 10.9701 17.4699 9.06006 15.1299 9.06006H4.12988C3.71988 9.06006 3.37988 8.72006 3.37988 8.31006C3.37988 7.90006 3.71988 7.56006 4.12988 7.56006H15.1299C18.2999 7.56006 20.8799 10.1401 20.8799 13.3101C20.8799 16.4801 18.2999 19.0601 15.1299 19.0601Z"
        fill="#292D32"
      />
      <path
        d="M6.43006 11.5599C6.24006 11.5599 6.05006 11.4899 5.90006 11.3399L3.34006 8.77988C3.05006 8.48988 3.05006 8.00988 3.34006 7.71988L5.90006 5.15988C6.19006 4.86988 6.67006 4.86988 6.96006 5.15988C7.25006 5.44988 7.25006 5.92988 6.96006 6.21988L4.93006 8.24988L6.96006 10.2799C7.25006 10.5699 7.25006 11.0499 6.96006 11.3399C6.82006 11.4899 6.62006 11.5599 6.43006 11.5599Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default ReturnToPortalIcon;
