const EditPencilIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.69332 13.5132C3.28666 13.5132 2.90666 13.3732 2.63332 13.1132C2.28666 12.7865 2.11999 12.2932 2.17999 11.7599L2.42666 9.59987C2.47332 9.1932 2.71999 8.6532 3.00666 8.35987L8.47999 2.56654C9.84666 1.11987 11.2733 1.07987 12.72 2.44654C14.1667 3.8132 14.2067 5.23987 12.84 6.68654L7.36666 12.4799C7.08666 12.7799 6.56666 13.0599 6.15999 13.1265L4.01332 13.4932C3.89999 13.4999 3.79999 13.5132 3.69332 13.5132ZM10.62 2.43987C10.1067 2.43987 9.65999 2.75987 9.20666 3.23987L3.73332 9.03987C3.59999 9.17987 3.44666 9.5132 3.41999 9.70654L3.17332 11.8665C3.14666 12.0865 3.19999 12.2665 3.31999 12.3799C3.43999 12.4932 3.61999 12.5332 3.83999 12.4999L5.98666 12.1332C6.17999 12.0999 6.49999 11.9265 6.63332 11.7865L12.1067 5.9932C12.9333 5.1132 13.2333 4.29987 12.0267 3.16654C11.4933 2.6532 11.0333 2.43987 10.62 2.43987Z"
        fill="#292D32"
      />
      <path
        d="M11.56 7.80022C11.5467 7.80022 11.5267 7.80022 11.5133 7.80022C9.43333 7.59356 7.76 6.01356 7.44 3.94689C7.4 3.67356 7.58666 3.42023 7.86 3.37356C8.13333 3.33356 8.38666 3.52023 8.43333 3.79356C8.68666 5.40689 9.99333 6.64689 11.62 6.80689C11.8933 6.83356 12.0933 7.08022 12.0667 7.35356C12.0333 7.60689 11.8133 7.80022 11.56 7.80022Z"
        fill="#292D32"
      />
      <path
        d="M14 15.6665H2C1.72667 15.6665 1.5 15.4398 1.5 15.1665C1.5 14.8932 1.72667 14.6665 2 14.6665H14C14.2733 14.6665 14.5 14.8932 14.5 15.1665C14.5 15.4398 14.2733 15.6665 14 15.6665Z"
        fill="#292D32"
      />
    </svg>
  );
};

export default EditPencilIcon;
