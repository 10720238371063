import React from "react";

const MenuIconBox = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.61526 1.62012H4.30188C2.79494 1.62012 2 2.41506 2 3.922V5.23538C2 6.74232 2.79494 7.53726 4.30188 7.53726H5.61526C7.1222 7.53726 7.91714 6.74232 7.91714 5.23538V3.922C7.91714 2.41506 7.1222 1.62012 5.61526 1.62012Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M5.61526 16.0791H4.30188C2.79494 16.0791 2 16.874 2 18.381V19.6944C2 21.2013 2.79494 21.9962 4.30188 21.9962H5.61526C7.1222 21.9962 7.91714 21.2013 7.91714 19.6944V18.381C7.91714 16.874 7.1222 16.0791 5.61526 16.0791Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M5.61526 9.0791H4.30188C2.79494 9.0791 2 9.87404 2 11.381V12.6944C2 14.2013 2.79494 14.9962 4.30188 14.9962H5.61526C7.1222 14.9962 7.91714 14.2013 7.91714 12.6944V11.381C7.91714 9.87404 7.1222 9.0791 5.61526 9.0791Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M12.6568 1.62012H11.3434C9.83645 1.62012 9.0415 2.41506 9.0415 3.922V5.23538C9.0415 6.74232 9.83645 7.53726 11.3434 7.53726H12.6568C14.1637 7.53726 14.9586 6.74232 14.9586 5.23538V3.922C14.9586 2.41506 14.1637 1.62012 12.6568 1.62012Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M12.6568 16.0791H11.3434C9.83645 16.0791 9.0415 16.874 9.0415 18.381V19.6944C9.0415 21.2013 9.83645 21.9962 11.3434 21.9962H12.6568C14.1637 21.9962 14.9586 21.2013 14.9586 19.6944V18.381C14.9586 16.874 14.1637 16.0791 12.6568 16.0791Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M12.6568 9.0791H11.3434C9.83645 9.0791 9.0415 9.87404 9.0415 11.381V12.6944C9.0415 14.2013 9.83645 14.9962 11.3434 14.9962H12.6568C14.1637 14.9962 14.9586 14.2013 14.9586 12.6944V11.381C14.9586 9.87404 14.1637 9.0791 12.6568 9.0791Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M19.698 1.62012H18.3846C16.8777 1.62012 16.0828 2.41506 16.0828 3.922V5.23538C16.0828 6.74232 16.8777 7.53726 18.3846 7.53726H19.698C21.205 7.53726 21.9999 6.74232 21.9999 5.23538V3.922C21.9999 2.41506 21.205 1.62012 19.698 1.62012Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M19.698 16.0791H18.3846C16.8777 16.0791 16.0828 16.874 16.0828 18.381V19.6944C16.0828 21.2013 16.8777 21.9962 18.3846 21.9962H19.698C21.205 21.9962 21.9999 21.2013 21.9999 19.6944V18.381C21.9999 16.874 21.205 16.0791 19.698 16.0791Z"
        fill={color ?? "#DE5439"}
      />
      <path
        d="M19.698 9.0791H18.3846C16.8777 9.0791 16.0828 9.87404 16.0828 11.381V12.6944C16.0828 14.2013 16.8777 14.9962 18.3846 14.9962H19.698C21.205 14.9962 21.9999 14.2013 21.9999 12.6944V11.381C21.9999 9.87404 21.205 9.0791 19.698 9.0791Z"
        fill={color ?? "#DE5439"}
      />
    </svg>
  );
};

export default MenuIconBox;
