import appNavIcon from "../../../assets/images/nav-apps-inactive-icon.svg";
import adminNavIcon from "../../../assets/images/nav-administration-inactive-icon.svg";
import analyticsNavIcon from "../../../assets/images/nav-analytics-inactive-icon.svg";
import integrationsNavIcon from "../../../assets/images/nav-integrations-inactive-icon.svg";
import datasheetsNavIcon from "../../../assets/images/nav-datasheets-inactive-icon.svg";
import dashboardsNavIcon from "../../../assets/images/nav-dashboards-inactive-icon.svg";
import settingsNavIcon from "../../../assets/images/nav-settings-inactive-icon.svg";
import tutorialNavIcon from "../../../assets/images/nav-support-inactive-icon.svg";
import editorHomeIcon from "../../../assets/images/editor-nav-home-icon.svg";
import editorUIEIcon from "../../../assets/images/editor-nav-uie-icon.svg";
import editorScreensIcon from "../../../assets/images/editor-nav-screen-icon.svg";
import editorWorkflowsIcon from "../../../assets/images/editor-nav-workflow-icon.svg";
import editorDatasheetsIcon from "../../../assets/images/editor-nav-data-icon.svg";
import notificationsIcon from "../../../assets/images/notifications_icon.svg";
import dashboardPortalIcon from "../../../assets/images/dashboard-icon.svg";
import activeDashboardPortalIcon from "../../../assets/images/active-dashboard.svg";
import activeAppsPortalIcon from "../../../assets/images/active-apps-icon.svg";
import activeAnalyticsPortalIcon from "../../../assets/images/active-analytics-icon.svg";
import activeRecordPortalIcon from "../../../assets/images/active-records-icon.svg";
import activeDatasheetPortalIcon from "../../../assets/images/active-datasheet-icon.svg";
import activeAccountSettingsPortalIcon from "../../../assets/images/active-account-setting.svg";
import appsPortalIcon from "../../../assets/images/app-icon.svg";
import analyticsPortalIcon from "../../../assets/images/analytics-icon.svg";
import recordPortalIcon from "../../../assets/images/record-icon.svg";
import accountSettingsPortalIcon from "../../../assets/images/account-setting-icon.svg";
import datasheetPortalIcon from "../../../assets/images/datasheet-icon.svg";
import tutorialPortalIcon from "../../../assets/images/tutorial-teacher-icon.svg";
import helpPortalIcon from "../../../assets/images/help-information-icon.svg";
import leftSelectorPortalIcon from "../../../assets/images/left-selector-Icon.svg";

export const allCountries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: 'Cote D"Ivoire', code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: 'Korea, Democratic People"S Republic of', code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: 'Lao People"S Democratic Republic', code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const statusBarWaitMessages = [
  "please wait...",
  "hold on...",
  "working on it...",
  "processing...",
  "busy...",
  "kindly hang on...",
  "this may take a moment...",
];

export const mainNavigationListNames = {
  APPS_N_TEMPLATES: {
    APPS_N_TEMPLATES: "Apps & Templates",
    APPS: "Apps",
    TEMPLATES: "Templates",
  },
  ADMINISTRATION: "Administration",
  ANALYTICS: "Analytics",
  INTEGRATIONS: "Integrations",
  DATASHEETS: "Datasheets",
  DASHBOARDS: "Dashboards",
  NOTIFICATIONS: "Notifications",
  SETTINGS: {
    SETTINGS: "Settings",
    ACCOUNT_PROFILE: "Account Profile",
    PASSWORD_CHANGE: "Password Change",
    USER_MANAGEMENT: "User Management",
    SSO_SIGN_IN: "SSO Configuration",
    CUSTOMIZATIONS: "Customizations",
    USER_GROUPS: "User Groups",
    ROLES: "Roles",
    APP_CATEGORIES: "App Categories",
    WEBHOOKS: "Webhooks",
    AUDIT_LOG: "Audit Log",
    BILLING_N_SUBSCRIPTIONS: "Billing & Subscriptions",
  },
};

export const mainNavigationUrls = {
  APPS_N_TEMPLATES: "/apps",
  APPS: "/apps",
  TEMPLATES: "/app-templates",
  ADMINISTRATION: "/administration",
  ANALYTICS: "/app-analytics",
  INTEGRATIONS: "/integrations",
  DATASHEETS: "/datasheets",
  DASHBOARDS: "/dashboards",
  NOTIFICATIONS: "/notifications",
  SETTINGS: "/settings",
  ACCOUNT_PROFILE: "/settings/profile",
  PASSWORD_CHANGE: "/settings/change-password",
  USER_MANAGEMENT: "/settings/user-management",
  SSO_SIGN_IN: "/settings/sso-configuration",
  CUSTOMIZATIONS: "/settings/customizations",
  USER_GROUPS: "/settings/user-groups",
  ROLES: "/settings/roles",
  APP_CATEGORIES: "/settings/app-categories",
  WEBHOOKS: "/settings/webhooks",
  AUDIT_LOG: "/settings/audit-log",
  BILLING_N_SUBSCRIPTIONS: "/settings/billing-subscription",
};

export const portalNavigationUrls = {
  DASHBOARD: "/portal/dashboard",
  APPS: "/portal/apps",
  ANALYTICS: "/portal/analytics",
  DATASHEET: "/portal/datasheet",
  RECORDS: "/portal/records",
  SETTINGS: "/portal/account-settings",
};

export const portalNavigationListNames = {
  DASHBOARD: "Dashboard",
  APPS: "Apps",
  ANALYTICS: "Analytics",
  DATASHEET: "Datasheet",
  RECORDS: "Records",
  SETTINGS: "Account Settings",
};

export const editorNavigationListNames = {
  HOME: "Home",
  UI_EDITOR: "UI Editor",
  SCREENS: "Screens",
  WORKFLOWS: "Workflows",
  DATASHEETS: "Datasheets",
};

export const editorNavigationUrls = {
  HOME: "/apps",
  UI_EDITOR: "/uieditor",
  SCREENS: "/screens",
  WORKFLOWS: "/workflows",
  DATASHEETS: "/datasheets",
};

export const unprotectedUrls = {
  ROOT: "/",
  APPENDAGE: "/appendage",
  LOGIN: "/login",
  LOGIN_WITH_SSO: "/login-sso",
  VERIFY_SSO: "/verify-sso",
  SIGN: "/sign",
  SIGNUP: "/sign-up",
  SIGNUP_DETAIL: "/signup-detail",
  WELCOME: "/welcome",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CREATE_PASSWORD: "/create-password",
  VERIFY: "/verify",
  RUN_COMPLETED: "/run/completed",
  RUN: "/run",
  DOWNLOAD_PUBLIC: `/download/public`,
  ERROR: "/error",
};

export const otherProtectedUrls = {
  APPENDAGE: `/appendage`,
  DASHBOARDS_VIEWER: `${mainNavigationUrls.DASHBOARDS}/viewer`,
  DASHBOARDS_EDITOR: `${mainNavigationUrls.DASHBOARDS}/editor`,
  EDITOR: `/editor`,
  EDITOR_UIEDITOR: `/editor/uieditor`,
  EDITOR_UIEDITOR_PREVIEW: `/editor/uieditor/preview`,
  EDITOR_UIEDITOR_PUBLISH: `/editor/uieditor/publish`,
  DOWNLOAD: `/download`,
  APPROVAL: `/approval`,
};

export const mainNavigationList = [
  {
    title: mainNavigationListNames.APPS_N_TEMPLATES.APPS_N_TEMPLATES,
    icon: <img src={appNavIcon} alt="navicon" />,
    url: mainNavigationUrls.APPS_N_TEMPLATES,
    additionalPermissionUrl: mainNavigationUrls.TEMPLATES,
    subMenu: [
      {
        title: mainNavigationListNames.APPS_N_TEMPLATES.APPS,
        icon: null,
        url: mainNavigationUrls.APPS,
      },
      {
        title: mainNavigationListNames.APPS_N_TEMPLATES.TEMPLATES,
        icon: null,
        url: mainNavigationUrls.TEMPLATES,
      },
    ],
  },
  {
    title: mainNavigationListNames.APPS_N_TEMPLATES.APPS,
    icon: <img src={appNavIcon} alt="navicon" />,
    url: mainNavigationUrls.APPS,
    exclusionPermissionUrl: mainNavigationUrls.TEMPLATES,
  },
  {
    title: mainNavigationListNames.ADMINISTRATION,
    icon: <img src={adminNavIcon} alt="navicon" />,
    url: mainNavigationUrls.ADMINISTRATION,
  },
  {
    title: mainNavigationListNames.ANALYTICS,
    icon: <img src={analyticsNavIcon} alt="navicon" />,
    url: mainNavigationUrls.ANALYTICS,
  },
  {
    title: mainNavigationListNames.INTEGRATIONS,
    icon: <img src={integrationsNavIcon} alt="navicon" />,
    url: mainNavigationUrls.INTEGRATIONS,
  },
  {
    title: mainNavigationListNames.DATASHEETS,
    icon: <img src={datasheetsNavIcon} alt="navicon" />,
    url: mainNavigationUrls.DATASHEETS,
  },
  {
    title: mainNavigationListNames.DASHBOARDS,
    icon: <img src={dashboardsNavIcon} alt="navicon" />,
    url: mainNavigationUrls.DASHBOARDS,
  },
  {
    title: mainNavigationListNames.NOTIFICATIONS,
    icon: (
      <img src={notificationsIcon} style={{ width: 15 }} alt="notifications" />
    ),
    url: mainNavigationUrls.NOTIFICATIONS,
    hidden: true,
  },
  {
    title: mainNavigationListNames.SETTINGS.SETTINGS,
    icon: <img src={settingsNavIcon} alt="navicon" />,
    url: mainNavigationUrls.SETTINGS,
    subMenu: [
      {
        title: mainNavigationListNames.SETTINGS.ACCOUNT_PROFILE,
        icon: null,
        url: mainNavigationUrls.ACCOUNT_PROFILE,
      },
      {
        title: mainNavigationListNames.SETTINGS.PASSWORD_CHANGE,
        icon: null,
        url: mainNavigationUrls.PASSWORD_CHANGE,
      },
      {
        title: mainNavigationListNames.SETTINGS.USER_MANAGEMENT,
        icon: null,
        url: mainNavigationUrls.USER_MANAGEMENT,
      },
      {
        title: mainNavigationListNames.SETTINGS.SSO_SIGN_IN,
        icon: null,
        url: mainNavigationUrls.SSO_SIGN_IN,
      },
      {
        title: mainNavigationListNames.SETTINGS.CUSTOMIZATIONS,
        icon: null,
        url: mainNavigationUrls.CUSTOMIZATIONS,
      },
      {
        title: mainNavigationListNames.SETTINGS.USER_GROUPS,
        icon: null,
        url: mainNavigationUrls.USER_GROUPS,
        hidden: true,
      },
      {
        title: mainNavigationListNames.SETTINGS.ROLES,
        icon: null,
        url: mainNavigationUrls.ROLES,
      },
      {
        title: mainNavigationListNames.SETTINGS.APP_CATEGORIES,
        icon: null,
        url: mainNavigationUrls.APP_CATEGORIES,
      },
      {
        title: mainNavigationListNames.SETTINGS.WEBHOOKS,
        icon: null,
        url: mainNavigationUrls.WEBHOOKS,
      },
      {
        title: mainNavigationListNames.SETTINGS.AUDIT_LOG,
        icon: null,
        url: mainNavigationUrls.AUDIT_LOG,
      },
      {
        title: mainNavigationListNames.SETTINGS.BILLING_N_SUBSCRIPTIONS,
        icon: null,
        url: mainNavigationUrls.BILLING_N_SUBSCRIPTIONS,
      },
    ],
  },
];

export const supportNavigationList = [
  {
    title: (
      <>
        Tutorial<span className="nav-coming-soon">Coming soon</span>
      </>
    ),
    icon: <img src={tutorialNavIcon} alt="navicon" />,
    url: "#",
    disabled: true,
    prompt: true,
  },
  /* {
    title: "Help",
    icon: <img src={helpNavIcon} alt="search" />,
    url: "/support/help",
  }, */
];

export const editorNavigationList = [
  {
    title: editorNavigationListNames.HOME,
    icon: <img src={editorHomeIcon} alt="home" />,
    url: editorNavigationUrls.HOME,
  },
  {
    title: editorNavigationListNames.UI_EDITOR,
    icon: <img src={editorScreensIcon} alt="uieditor" />,
    url: editorNavigationUrls.UI_EDITOR,
  },
  /* {
    title: editorNavigationListNames.SCREENS,
    icon: <img src={editorScreensIcon} alt="screens" />,
    url: editorNavigationUrls.SCREENS,
  }, */
  {
    title: editorNavigationListNames.WORKFLOWS,
    icon: <img src={editorWorkflowsIcon} alt="workflows" />,
    url: editorNavigationUrls.WORKFLOWS,
  },
  {
    title: editorNavigationListNames.DATASHEETS,
    icon: <img src={editorDatasheetsIcon} alt="datasheets" />,
    url: editorNavigationUrls.DATASHEETS,
  },
];

export const portalNavigationList = [
  {
    title: portalNavigationListNames.DASHBOARD,
    icon: <img src={dashboardPortalIcon} alt="navicon" />,
    activeIcon: <img src={activeDashboardPortalIcon} alt="navicon" />,
    url: portalNavigationUrls.DASHBOARD,
  },
  {
    title: portalNavigationListNames.APPS,
    icon: <img src={appsPortalIcon} alt="navicon" />,
    activeIcon: <img src={activeAppsPortalIcon} alt="navicon" />,
    url: portalNavigationUrls.APPS,
  },
  {
    title: portalNavigationListNames.ANALYTICS,
    icon: <img src={analyticsPortalIcon} alt="navicon" />,
    activeIcon: <img src={activeAnalyticsPortalIcon} alt="navicon" />,
    url: "#",
  },
  {
    title: portalNavigationListNames.DATASHEET,
    icon: <img src={datasheetPortalIcon} alt="navicon" />,
    activeIcon: <img src={activeDatasheetPortalIcon} alt="navicon" />,
    url: "#",
  },
  {
    title: portalNavigationListNames.RECORDS,
    icon: <img src={recordPortalIcon} alt="navicon" />,
    activeIcon: <img src={activeRecordPortalIcon} alt="navicon" />,
    url: portalNavigationUrls.RECORDS,
  },
  {
    title: portalNavigationListNames.SETTINGS,
    icon: <img src={accountSettingsPortalIcon} alt="navicon" />,
    activeIcon: <img src={activeAccountSettingsPortalIcon} alt="navicon" />,
    url: "#",
    rightIcon: <img src={leftSelectorPortalIcon} alt="navicon" />,
  },
];

export const portalSupportNavigationList = [
  {
    title: "Tutorial",
    icon: <img src={tutorialPortalIcon} alt="navicon" />,
    url: "#",
  },
  {
    title: "Help",
    icon: <img src={helpPortalIcon} alt="navicon" />,
    url: "#",
  },
];
